import "core-js/modules/es6.array.filter.js";
import { EXPERIMENTS_SET_ACTIVE, EXPERIMENTS_DELETED } from 'redux/actionTypes/experiments';
import { SAMPLES_DELETE } from 'redux/actionTypes/samples';
var removeExperiment = function removeExperiment(experimentId) {
  return function (dispatch, getState) {
    // If deleted project is the same as the active project, choose another project
    var _getState = getState(),
      experiments = _getState.experiments;
    var activeExperimentId = experiments.meta.activeExperimentId;
    if (experimentId === activeExperimentId) {
      var _leftoverProjectIds = experiments.ids.filter(function (uuid) {
        return uuid !== activeExperimentId;
      });
      dispatch({
        type: EXPERIMENTS_SET_ACTIVE,
        payload: {
          experimentId: _leftoverProjectIds.length ? _leftoverProjectIds[0] : null
        }
      });
    }
    dispatch({
      type: SAMPLES_DELETE,
      payload: {
        experimentId: experimentId,
        sampleIds: experiments[experimentId].sampleIds
      }
    });
    dispatch({
      type: EXPERIMENTS_DELETED,
      payload: {
        experimentIds: [experimentId]
      }
    });
  };
};
export default removeExperiment;